import React from "react"
import styled from "styled-components"

import { VSection, ValueProp, Grid } from "components/common"

import { matrixBackgroundStyle, appColors, device } from "utils"

const AboutHero = ({ title, subtitle, valueProps, media, backgroundImage }) => {
  const renderGrid = () => (
    <Grid
      list={valueProps}
      component={(vp) => (
        <ValueProp orientation="horizontal" className={vp.vpId} {...vp} />
      )}
    />
  )

  return (
    <StyledAboutHero>
      <VSection
        title={title}
        titleAs="h1"
        subtitle={subtitle}
        grid={renderGrid}
        media={media}
        backgroundImage={backgroundImage}
        critical
      />
    </StyledAboutHero>
  )
}

const StyledAboutHero = styled.div`
  --section-mobile-padding-top: var(--sp-mobile-nav);
  --section-padding-top: var(--sp-nav);
  --section-padding-bottom: var(--sp-40);
  --v-section-media-top: 120px;
  .v_tgContainer {
    --tg-text-align: center;
    --section-align: center;
    --tg-max-width: 711px;
  }

  .v_gridContainer {
    --section-align: center;
    --grid-gap: var(--sp-64);
    --section-order: 2;
    --section-mobile-order: 2;
    --grid-col-max: 1fr;
    --grid-col: 1;
    --v-section-grid-top: var(--sp-96);

    --tg-max-width: 685px;
    --vp-mobile-grid-gap: var(--sp-32);
    --vp-grid-gap: var(--sp-40);

    --tg-text-align: center;

    @media ${device.laptop} {
      --tg-text-align: left;
    }

    li {
      .vp_mediaContainer {
        text-align: center;
        @media ${device.laptop} {
          text-align: left;
        }

        img {
          border-radius: 50%;
          box-shadow: var(--card-shadow);
        }
      }

      &:nth-child(1) {
        strong {
          color: ${appColors.documents.light};
        }
      }
      &:nth-child(2) {
        strong {
          color: ${appColors.fax.light};
        }
      }
      &:nth-child(3) {
        strong {
          color: ${appColors.yellow.light};
        }
      }
      &:nth-child(4) {
        strong {
          color: ${appColors.signature.light};
        }
      }
      &:nth-child(5) {
        strong {
          color: ${appColors.ship.light};
        }
      }
    }
  }

  .v_mediaContainer {
    --section-align: center;
    --section-order: 3;
    --section-mobile-order: 3;
    margin: 0 auto;
    margin-top: var(--v-section-mobile-media-top);

    @media ${device.laptop} {
      margin-top: var(--v-section-media-top);
      width: 550px;
    }
  }

  .v_backgroundImage {
    ${matrixBackgroundStyle};
  }
`

export default AboutHero
