import React from "react"
import { graphql } from "gatsby"
// import styled from "styled-components"

import { useContentfulSection, useMeta } from "utils"

import { Layout, Seo } from "components/common"

import { PrimaryNav, MobileNav } from "src/components/navigation"

import { AboutHero, AboutQuote } from "src/sections/about"

const AboutPage = ({ data: { about } }) => {
  const meta = useMeta(about)
  const { about_hero, about_quote } = useContentfulSection(about.sections)

  return (
    <Layout>
      <Seo {...meta} />
      <MobileNav />
      <PrimaryNav />
      <AboutHero {...about_hero} />
      <AboutQuote {...about_quote} />
      {/* <AboutLinks {...about_links} /> */}
    </Layout>
  )
}

export const query = graphql`
  {
    about: contentfulPage(pageId: { eq: "about" }) {
      ...Page
    }
  }
`

export default AboutPage
