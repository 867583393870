import React from "react"
import styled from "styled-components"

import { VSection, QuoteCard } from "components/common"

const AboutQuote = ({ title, subtitle, hatIcon }) => {
  return (
    <StyledAboutQuote>
      <VSection
        custom={() => (
          <QuoteCard
            icon={{ desktop: hatIcon }}
            title={title}
            subtitle={subtitle}
          />
        )}
      />
    </StyledAboutQuote>
  )
}

const StyledAboutQuote = styled.div`
  .v_customContainer {
    max-width: 1040px;
    margin: 0 auto;
    --section-mobile-padding-left: 0;
    --section-mobile-padding-right: 0;
  }
`

export default AboutQuote
