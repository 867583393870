import React from "react"
import styled from "styled-components"

import { VSection, ValueProp, Grid } from "components/common"

const AboutLinks = ({ valueProps }) => {
  const renderGrid = () => (
    <Grid
      list={valueProps}
      component={(vp) => <ValueProp titleAs="h2" className={vp.vpId} {...vp} />}
    />
  )

  return (
    <StyledAbouteLinks>
      <VSection grid={renderGrid} />
    </StyledAbouteLinks>
  )
}

const StyledAbouteLinks = styled.div`
  .v_gridContainer {
    --section-align: center;
    --grid-gap: var(--sp-96);

    --grid-col-max: 315px;
    --grid-col: 2;
    --tg-text-align: center;
    --vp-grid-gap: var(--sp-32);
    --vp-mobile-grid-gap: var(--sp-32);
    --vp-align: center;

    .vp_mediaContainer {
      text-align: center;
      max-width: 215px;
      margin: 0 auto;
      /* img {
        border-radius: 50%;
        box-shadow: var(--card-shadow);
      } */
    }
  }
`

export default AboutLinks
